/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';

export const GeneralError = ({
    status,
    statusText,
    data,
    image,
}: {
    status?: number;
    statusText?: string;
    data?: any;
    image?: {
        height?: number;
        width?: number;
    };
}) => {
    const { t } = useTranslation();

    statusText = statusText || t('error.general');

    return (
        <div className='w-full h-full flex flex-col items-center justify-center text-center text-xs'>
            <div className='max-w-xs mx-auto'>
                <img
                    height={image?.height}
                    width={image?.width}
                    src='../images/generic-error.png'
                    className='max-w-full w-auto h-auto'
                    alt='Ready2Order Error'
                />
            </div>
            <div className='max-w-1/2 mx-auto'>
                <h2>{status ? status : '' + statusText}</h2>
                <p>{data}</p>
                <p className='m-2.5'>{t('error.text')}</p>
                <a
                    className='underline'
                    href={t('error.link')}
                    title={t('error.title')}
                >
                    {t('error.here')}
                </a>
            </div>
        </div>
    );
};
